.App {
  text-align: center;
}

.App-header {
  background-color: #003350;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  font-family: 'Anton', sans-serif;
  margin:0;
  padding:0;
  overflow: hidden;
}
.Particles {
  position: absolute;
  height:100%;
  width:100%;
  overflow:hidden;
}
.Buttons{
  flex-direction: row;
}
.Name{
  font-size:3em;
  margin:22px;
}
.Title{
  margin:8px;
}